import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "10",
  height: "9",
  viewBox: "0 0 10 9",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  class: "icon--svg icon"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M1.49438 8.93067C1.21999 8.93067 0.98509 8.8365 0.789687 8.64818C0.594284 8.45986 0.496582 8.23347 0.496582 7.96902V1.23752C0.496582 0.97307 0.594284 0.746683 0.789687 0.558362C0.98509 0.37004 1.21999 0.275879 1.49438 0.275879H4.98669V1.23752H1.49438V7.96902H8.479V4.60327H9.47681V7.96902C9.47681 8.23347 9.3791 8.45986 9.1837 8.64818C8.9883 8.8365 8.7534 8.93067 8.479 8.93067H1.49438ZM3.83922 6.38231L3.14076 5.70916L7.78054 1.23752H5.9845V0.275879H9.47681V3.64163H8.479V1.91067L3.83922 6.38231Z",
      fill: "currentColor"
    }, null, -1)
  ])))
}
export default { render: render }